import { useRouter } from 'next/router';
import type React from 'react';
import { type FunctionComponent, useEffect, useRef, useState } from 'react';
import { IntlProvider } from 'react-intl';

import { type IntlMessages, loadTranslations } from '../../services/i18n';

export type I18nProviderProps = {
  initialMessages: IntlMessages;
};

/**
 * Describe I18nProvider here.
 */
export const I18nProvider: FunctionComponent<
  React.PropsWithChildren<I18nProviderProps>
> = ({ children, initialMessages }) => {
  const { locale: nextLocale, defaultLocale: nextDefaultLocale } = useRouter();
  // Next.js types for router make these optional because they depend on
  // configuration. In our case we _know_ locales are configured.
  const defaultLocale = nextDefaultLocale as string;
  const locale = nextLocale as string;

  const [messages, setMessages] = useState(initialMessages);

  // Don't run this on initial render in the client. SSR has already provided
  // messages.
  const isFirstRun = useRef(true);
  useEffect(() => {
    if (!isFirstRun.current) {
      loadTranslations(locale).then(setMessages);
    }
    isFirstRun.current = false;
  }, [locale]);

  return (
    <IntlProvider
      locale={locale}
      defaultLocale={defaultLocale}
      // The react-intl messages type seems wrong for messages in AST format.
      messages={messages as unknown as Record<string, string>}
    >
      {children}
    </IntlProvider>
  );
};
