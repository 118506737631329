import getConfig from 'next/config';

import { isBoolean, isObject, isString } from '../../utils/guards';
import { logger } from '../logger';

export type Environment = 'development' | 'test' | 'production';

function isEnvironment(value: unknown): value is Environment {
  if (!isString(value)) {
    return false;
  }

  return ['development', 'test', 'production'].includes(value);
}

export type URLConfig = {
  basePath: string;
  apiBasePath: string;
  dashboardBaseURL: string;
  dashboardSignupURL: string;
  pwcheckURL: string;
};

function isURLConfig(value: unknown): value is URLConfig {
  if (!isObject(value)) {
    return false;
  }

  return (
    isString(value.basePath) &&
    isString(value.apiBasePath) &&
    isString(value.dashboardBaseURL) &&
    isString(value.pwcheckURL)
  );
}

type PublicRuntimeConfig = {
  dashboardBaseURL: string;
  dashboardSignupURL: string;
  localeCookieDomain?: string;
  pwcheckURL: string;
  multiMerchantEnabled?: boolean;
  enableAnalytics?: boolean;
};

const enableLogging =
  typeof window === 'undefined' || process.env.NODE_ENV === 'development';

function parsePublicRuntimeConfig(config: unknown): PublicRuntimeConfig {
  if (!isObject(config)) {
    throw new Error('invalid nextPublicRuntimeConfig');
  }

  if (!isString(config?.dashboardBaseURL)) {
    throw new Error('missing runtime config value: dashboardBaseURL');
  }

  if (!isString(config?.dashboardSignupURL)) {
    throw new Error('missing runtime config value: dashboardSignupURL');
  }

  if (!isString(config?.pwcheckURL)) {
    throw new Error('missing runtime config value: pwcheckURL');
  }

  if (
    !isString(config?.localeCookieDomain) &&
    process.env.NODE_ENV === 'production'
  ) {
    throw new Error('missing runtime config value: localeCookieDomain');
  }

  const parsedConfig: PublicRuntimeConfig = {
    dashboardBaseURL: config.dashboardBaseURL,
    dashboardSignupURL: config.dashboardSignupURL,
    pwcheckURL: config.pwcheckURL,
  };

  if (isBoolean(config?.multiMerchantEnabled)) {
    parsedConfig.multiMerchantEnabled = config.multiMerchantEnabled;
  }

  if (isString(config?.localeCookieDomain)) {
    parsedConfig.localeCookieDomain = config.localeCookieDomain;
  }

  if (isBoolean(config?.enableAnalytics)) {
    parsedConfig.enableAnalytics = config.enableAnalytics;
  }

  return parsedConfig;
}

type NextRuntimeConfig = {
  [key in string | number]: string | boolean | Record<string, string | boolean>;
};

function isNextRuntimeConfig(value: unknown): value is NextRuntimeConfig {
  if (!isObject(value)) {
    return false;
  }

  return Object.entries(value).reduce(
    (isValid: boolean, [key, val]: [unknown, unknown]) =>
      isValid &&
      isString(key) &&
      (isString(val) || isBoolean(val) || isObject(val)),
    true,
  );
}

type NextRuntimeConfigs = {
  serverRuntimeConfig: NextRuntimeConfig;
  publicRuntimeConfig: NextRuntimeConfig;
};

function isNextRuntimeConfigs(value: unknown): value is NextRuntimeConfigs {
  if (!isObject(value)) {
    return false;
  }

  return (
    isNextRuntimeConfig(value?.serverRuntimeConfig) &&
    isNextRuntimeConfig(value?.publicRuntimeConfig)
  );
}

const nextRuntimeConfigs = getConfig() as unknown;
if (!isNextRuntimeConfigs(nextRuntimeConfigs)) {
  throw new Error('invalid Next.js runtime configs');
}

// Parse Next.js public runtime config to get type-safety
const publicRuntimeConfig = parsePublicRuntimeConfig(
  nextRuntimeConfigs.publicRuntimeConfig,
);

export type ClientConfig = {
  name: string;
  env: Environment;
  urls: URLConfig;
  localeCookieDomain?: string;
  multiMerchantEnabled: boolean;
  dashboardBaseURL: string;
  enableAnalytics: boolean;
};

export function isClientConfig(value: unknown): value is ClientConfig {
  if (!isObject(value)) {
    if (enableLogging) {
      logger.error('failed to validate config as object');
    }
    return false;
  }

  if (!isURLConfig(value.urls)) {
    if (enableLogging) {
      logger.error('failed to validate urls config');
    }
    return false;
  }

  if (!isString(value.name)) {
    if (enableLogging) {
      logger.error('failed to validate name');
    }
    return false;
  }

  if (!isEnvironment(value.env)) {
    if (enableLogging) {
      logger.error('failed to validate env');
    }
    return false;
  }

  if (
    process.env.NODE_ENV === 'production' &&
    !isString(value?.localeCookieDomain)
  ) {
    if (enableLogging) {
      logger.error(
        `invalid value for localeCookieDomain: ${JSON.stringify(
          value?.localeCookieDomain,
        )}`,
      );
    }
    return false;
  }

  if (!isBoolean(value.multiMerchantEnabled)) {
    if (enableLogging) {
      logger.error('failed to validate multiMerchantEnabled');
    }
    return false;
  }

  return true;
}

function parseClientConfig(runtimeConfig: PublicRuntimeConfig): ClientConfig {
  const config = {
    name: process.env.NEXT_PUBLIC_PORTIER_NAME,
    env: process.env.NODE_ENV,
    urls: {
      basePath: process.env.NEXT_PUBLIC_PORTIER_BASE_PATH,
      apiBasePath: process.env.NEXT_PUBLIC_PORTIER_API_BASE_PATH,
      dashboardBaseURL: runtimeConfig.dashboardBaseURL,
      dashboardSignupURL: runtimeConfig.dashboardSignupURL,
      pwcheckURL: runtimeConfig.pwcheckURL,
    },
    localeCookieDomain: runtimeConfig.localeCookieDomain,
    multiMerchantEnabled: !!runtimeConfig.multiMerchantEnabled,
    dashboardBaseURL: runtimeConfig.dashboardBaseURL,
    enableAnalytics: !!runtimeConfig.enableAnalytics,
  };

  if (!isClientConfig(config)) {
    throw new Error('invalid client config');
  }

  return config;
}

export const config: ClientConfig = parseClientConfig(publicRuntimeConfig);
