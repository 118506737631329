import Cookie from 'js-cookie';

// Parses the Google Analytics client ID from the _ga cookie.
export const getGaClientId = () =>
  Cookie.get('_ga')?.split('.').slice(2).join('.') ?? 'default';

export const getGaSessionInformation = () => {
  const gtmContainerId = 'W85B32FNHB';
  const sessionInfo =
    Cookie.get(`_ga_${gtmContainerId}`)?.split('.').slice(2, 6) ?? [];

  return {
    session_id: sessionInfo[0],
    session_number: sessionInfo[1],
  };
};
