import { useEffect } from 'react';

export const useHistoryOverride = (backURL?: string) => {
  useEffect(() => {
    if (!backURL) {
      return;
    }

    if (document.referrer) {
      const currentURL = new URL(document.URL);
      const parsedReferrer = new URL(document.referrer);

      // we are coming from a different domain other than SSO
      if (parsedReferrer.hostname !== currentURL.hostname) {
        window.history.pushState(null, '', document.URL);

        const listenerCallback = () => {
          window.location.href = backURL;
        };
        window.addEventListener('popstate', listenerCallback);

        return () => window.removeEventListener('popstate', listenerCallback);
      }
    }
  }, [backURL]);
};
