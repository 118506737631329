import type { NextPageContext } from 'next';

type NextQueryObject = NextPageContext['query'];

const ERROR_PARAMS = new Set(['error', 'error_description']);

export function getSafeSearchParams(query: NextQueryObject) {
  const searchParams = toURLSearchParams(query);

  const backendParams = parseBackendQueryParams(searchParams);
  const safeSearch = removeParams(
    new Set([...ERROR_PARAMS, ...backendParams]),
    searchParams,
  );

  return safeSearch;
}

export function appendSafeQueryString(
  url: string,
  query: NextQueryObject,
): string {
  const searchParams = toURLSearchParams(query);
  return appendSafeSearchParamsString(url, searchParams);
}

export function appendSafeSearchParamsString(
  url: string,
  search: URLSearchParams,
): string {
  const backendParams = parseBackendQueryParams(search);
  const safeSearch = removeParams(
    new Set([...ERROR_PARAMS, ...backendParams]),
    search,
  );
  return appendSearchParamsString(url, safeSearch);
}

function parseBackendQueryParams(search: URLSearchParams): Set<string> {
  const clientParams = search.getAll('original_params');
  const params = new Set([...search.keys()]);

  if (!clientParams.length) {
    return new Set();
  }

  clientParams.forEach((name) => {
    params.delete(name);
  });

  return params;
}

export function appendSearchParamsString(
  url: string,
  search: URLSearchParams,
): string {
  const queryString = search.toString();
  if (!queryString.length) {
    return url;
  }

  return `${url}?${queryString}`;
}

export function optionalQueryParam(
  param: string | string[] | undefined,
): string | undefined {
  if (Array.isArray(param)) {
    return param[0];
  }

  return param;
}

function removeParams(
  params: Set<string>,
  search: URLSearchParams,
): URLSearchParams {
  const newSearch = new URLSearchParams();

  search.forEach((value, key) => {
    if (params.has(key)) {
      return;
    }

    newSearch.append(key, value);
  });

  return newSearch;
}

function toURLSearchParams(query: NextQueryObject): URLSearchParams {
  const queryParams = new URLSearchParams();
  Object.entries(query).forEach(([key, value]) => {
    if (typeof value === 'undefined') {
      return;
    }

    if (Array.isArray(value)) {
      value.forEach((v) => {
        queryParams.append(key, v);
      });
      return;
    }

    queryParams.append(key, value);
  });

  return queryParams;
}
