import { type MessageDescriptor, defineMessage } from 'react-intl';

import { HttpStatusCode } from './http-error-codes';

type AppErrorParams = {
  message: string;
  userTitle: MessageDescriptor;
  userMessage: MessageDescriptor;
  statusCode?: HttpStatusCode;
  route?: string;
  report?: boolean;
};

export class AppError extends Error {
  message: string;

  route?: string;

  statusCode?: HttpStatusCode;

  userTitle: MessageDescriptor;

  userMessage: MessageDescriptor;

  report = true;

  constructor({
    message,
    userTitle,
    userMessage,
    statusCode,
    route,
    report,
  }: AppErrorParams) {
    super(message);

    this.message = message;
    this.userMessage = userMessage;
    this.userTitle = userTitle;

    if (route) {
      this.route = route;
    }

    if (statusCode) {
      this.statusCode = statusCode;
    }

    if (typeof report !== 'undefined') {
      this.report = report;
    }
  }
}

export function isAppError(something: unknown): something is AppError {
  return something instanceof AppError;
}

export const defaultError = new AppError({
  message: 'Unknown Error',
  userTitle: defineMessage({
    defaultMessage: 'Unknown Error',
    description: 'Title for the "unknown error"-page.',
  }),
  userMessage: defineMessage({
    defaultMessage:
      'We are really sorry, but something went wrong and we could not handle the issue. Please try again later.',
    description:
      'Default error message shown to users and on error screen, when we are unable to handle problems automatically',
  }),
  statusCode: HttpStatusCode.InternalServerError,
});

export const errNotFound = new AppError({
  message: 'Page not found',
  userTitle: defineMessage({
    defaultMessage: 'Page Not Found',
    description: 'Title for the "Page Not Found" error page.',
  }),
  userMessage: defineMessage({
    defaultMessage:
      'We are sorry, but we cannot find the page your are looking for.',
    description:
      'Error message shown to users when the page they are trying to access does not exist.',
  }),
  statusCode: HttpStatusCode.NotFound,
  report: false,
});
