import type { NextPage } from 'next';
import { type MessageDescriptor, useIntl } from 'react-intl';

import { ErrorComponent } from '../components/Error';
import { config } from '../lib/config/client';
import { HttpStatusCode, defaultError, isAppError } from '../lib/errors';

export type ErrorPageProps = {
  title: MessageDescriptor;
  message: MessageDescriptor;
  dashboardURL: string;
};

const ErrorPage: NextPage<ErrorPageProps> = ({
  message,
  title,
  dashboardURL,
}) => {
  const intl = useIntl();

  return (
    <ErrorComponent
      title={intl.formatMessage(title)}
      message={intl.formatMessage(message)}
      dashboardURL={dashboardURL}
    />
  );
};

ErrorPage.getInitialProps = ({ res, err }) => {
  // We're dealing with an unhandled server error.
  if (res) {
    const statusCode =
      err?.statusCode || res?.statusCode || HttpStatusCode.InternalServerError;
    res.statusCode = statusCode;
  }

  if (isAppError(err)) {
    return {
      title: err.userTitle,
      message: err.userMessage,
      dashboardURL: config.urls.dashboardBaseURL,
    };
  }

  return {
    title: defaultError.userTitle,
    message: defaultError.userMessage,
    dashboardURL: config.urls.dashboardBaseURL,
  };
};

export default ErrorPage;
