import type { ParsedUrlQuery } from 'querystring';

import type {
  GetServerSideProps,
  GetServerSidePropsContext,
  GetServerSidePropsResult,
} from 'next';

import type { ErrorPageProps } from '../../pages/_error';
import { config } from '../config/client';
import { logger } from '../logger';

import { AppError, defaultError } from './errors';
import { HttpStatusCode } from './http-error-codes';

export type ServerErrorProps = {
  serverError: ErrorPageProps;
};

type AnyProps = { [key: string]: any };

export function handleServerError<
  P extends AnyProps = AnyProps,
  Q extends ParsedUrlQuery = ParsedUrlQuery,
>(getServerSidePageProps: GetServerSideProps<P, Q>) {
  return async (
    ctx: GetServerSidePropsContext<Q>,
  ): Promise<GetServerSidePropsResult<P | ServerErrorProps>> => {
    try {
      const pageProps = await getServerSidePageProps(ctx);
      return pageProps;
    } catch (err: unknown) {
      const { resolvedUrl } = ctx;

      const handledError = err instanceof AppError ? err : defaultError;
      const statusCode =
        handledError.statusCode || HttpStatusCode.InternalServerError;

      if (err instanceof Error) {
        const requestID = ctx.req.headers['x-request-id'];
        const logData: Record<string, string | Error | number> = {
          type: 'exception',
          error: err.message,
          resolvedUrl,
          statusCode,
        };

        if (requestID) {
          logData.requestID = Array.isArray(requestID)
            ? requestID[0]
            : requestID;
        }

        logger.error(logData, 'failed to get server-side props');
      }

      ctx.res.statusCode = statusCode;

      const serverError = {
        dashboardURL: config.urls.dashboardBaseURL,
        message: handledError.userMessage,
        title: handledError.userTitle,
      };

      return {
        props: {
          serverError,
        },
      };
    }
  };
}
