import Pino from 'pino';

export const logger = Pino({
  messageKey: 'message',
  base: undefined,
  level: 'error',
  formatters: {
    level: (label: string) => ({
      level: label === 'warn' ? 'warning' : label,
    }),
  },
});
