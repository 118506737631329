import { Anchor } from '@sumup-oss/circuit-ui';
import { SumUpLogo } from '@sumup-oss/icons';
import type { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

import { getWebsiteURL, safeLocale } from '../../services/i18n';

import styles from './styles.module.css';

type LogoProps = {
  renderLink?: boolean;
};

export const Logo: FunctionComponent<LogoProps> = (props: LogoProps) => {
  const { renderLink = true } = props;
  const intl = useIntl();
  const locale = safeLocale(intl.locale);
  const websiteUrl = getWebsiteURL(locale);

  const logo = <SumUpLogo className={styles.logo} />;

  if (!renderLink) {
    return logo;
  }

  return (
    <Anchor
      href={websiteUrl}
      title={intl.formatMessage({
        defaultMessage: "Go to SumUp's website",
        description:
          'accessibility label for an image link to the marketing SumUp website',
      })}
      target="_blank"
      rel="noopener noreferrer"
    >
      {logo}
    </Anchor>
  );
};
