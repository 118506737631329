/**
 * This file is generated using scripts/generate-website-urls.js.
 * Update whenever we add new localizations.
 */

import { type Country, type Locale, getLocaleCountry } from './i18n';

export const websiteURLs: Record<Country, string> = {
  AU: 'https://sumup.com.au',
  AR: 'https://sumup.com',
  BG: 'https://sumup.bg',
  CZ: 'https://sumup.cz',
  CA: 'https://sumup.com',
  DK: 'https://sumup.dk',
  AT: 'https://sumup.at',
  CH: 'https://ch-de.sumup.com',
  DE: 'https://sumup.de',
  LU: 'https://sumup.lu',
  CY: 'https://sumup.com.cy',
  GR: 'https://sumup.gr',
  GB: 'https://sumup.co.uk',
  IE: 'https://sumup.ie',
  MT: 'https://sumup.com.mt',
  US: 'https://sumup.com',
  CL: 'https://sumup.cl',
  CO: 'https://sumup.co',
  ES: 'https://sumup.es',
  PE: 'https://sumup.pe',
  EE: 'https://sumup.ee',
  FI: 'https://sumup.fi',
  BE: 'https://nl.sumup.be',
  FR: 'https://sumup.fr',
  HU: 'https://sumup.hu',
  IT: 'https://sumup.it',
  LT: 'https://sumup.lt',
  LV: 'https://sumup.lv',
  MX: 'https://sumup.com.mx',
  NO: 'https://sumup.no',
  NL: 'https://sumup.nl',
  BR: 'https://sumup.com.br',
  PT: 'https://sumup.pt',
  PL: 'https://sumup.pl',
  RO: 'https://sumup.ro',
  SK: 'https://sumup.sk',
  SI: 'https://sumup.si',
  SE: 'https://sumup.se',
};

const websiteOverrides: Partial<
  Record<string, Partial<Record<Terms, string>>>
> = {
  'https://sumup.ro': {
    terms: '/informatii-juridice/',
    privacy: '/confidentialitate/',
  },
  'https://sumup.ee': {
    privacy: '/privaatsus/',
  },
  'https://sumup.hr': {
    terms: '/pravne-informacije/',
    privacy: '/privatnost/',
  },
  'https://sumup.co': {
    terms: '/terms/',
    privacy: '/privacidad/',
  },
  'https://fr.sumup.be': {
    terms: '/conditions-2021/',
  },
  'https://nl.sumup.be': {
    terms: '/algemene-voorwaarden/',
    privacy: '/privacybeleid/',
  },
  'https://sumup.dk': {
    privacy: '/fortrolighedspolitik/',
  },
  'https://sumup.cl': {
    terms: '/terminos-y-condiciones/',
    privacy: '/aviso-de-privacidad/',
    purchase: '/terminos-y-condiciones/',
    card: '/terms-sumup-card/',
  },
  'https://sumup.es': {
    terms: '/terminos-y-condiciones/',
    privacy: '/aviso-de-privacidad/',
  },
  'https://sumup.pe': {
    terms: '/terminos-y-condiciones/',
    privacy: '/politica-de-privacidad/',
  },
  'https://fr.sumup.lu': {
    terms: '/conditions-2021/',
  },
  'https://de.sumup.lu/': {
    terms: '/geschaftsbedingungen/',
  },
  'https://ch-de.sumup.com': {
    terms: '/geschaeftsbedingungen/',
    privacy: '/datenschutzbestimmungen/',
  },
  'https://ch-it.sumup.com': {
    terms: '/termini-condizioni/',
    privacy: '/tutela-privacy/',
  },
  'https://ch-fr.sumup.com': {
    terms: '/conditions-generales/',
    privacy: '/protection-des-donnees/',
  },
  'https://sumup.com.au': {
    terms: '/terms/',
    privacy: '/privacy/',
  },
  'https://sumup.com.mx': {
    terms: '/terminos-y-condiciones/',
    privacy: '/aviso-de-privacidad/',
  },
};

type Terms = 'terms' | 'privacy' | 'purchase' | 'card';

export function termsURL(
  type: 'terms' | 'privacy' | 'purchase' | 'card',
  country: Country,
): string {
  const url = websiteURLs[country] || 'https://sumup.com';
  const links = {
    terms: '/terms-plain',
    privacy: '/privacy-plain',
    purchase: '/purchase-plain',
    card: '/terms-sumup-card',
    ...websiteOverrides[url],
  };
  return `${url}${links[type]}`;
}

export function getWebsiteURL(locale: Locale): string {
  const country = getLocaleCountry(locale);
  return websiteURLs[country] || 'https://sumup.com';
}

export const statusUrl = 'https://status.sumup.com';
