import { Body, Button, Headline } from '@sumup-oss/circuit-ui';
import Head from 'next/head';
import { Fragment, type FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

import { Logo } from '../Logo';

import styles from './styles.module.css';

export interface ErrorProps {
  message: string;
  title: string;
  dashboardURL: string;
}

export const ErrorComponent: FunctionComponent<ErrorProps> = ({
  message,
  title,
  dashboardURL,
}) => {
  const intl = useIntl();

  return (
    <Fragment>
      <Head>
        <title key="title">{title}</title>
      </Head>
      <div className={styles.container}>
        <a
          href="https://sumup.com"
          target="_blank"
          rel="noopener noreferrer"
          aria-label={title}
        >
          <Logo renderLink={false} />
        </a>
        <main className={styles.main}>
          <div>
            <Headline as="h1">{title}</Headline>

            <Body className={styles.bottomSpacing}>{message}</Body>

            <Button href={dashboardURL} variant="primary">
              {intl.formatMessage({
                defaultMessage: 'Visit Dashboard',
                description: 'Button text to navigate to the Dashboard',
              })}
            </Button>
          </div>
        </main>
      </div>
    </Fragment>
  );
};
