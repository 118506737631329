import { AppError, defaultError } from './errors';
import { HttpStatusCode } from './http-error-codes';

const oAuth2ErrorHttpStatus = (error: string): HttpStatusCode => {
  const errorStatusMapping: { [key: string]: HttpStatusCode } = {
    invalid_request_uri: HttpStatusCode.BadRequest,
    invalid_request_object: HttpStatusCode.BadRequest,
    consent_required: HttpStatusCode.BadRequest,
    interaction_required: HttpStatusCode.BadRequest,
    invalid_request: HttpStatusCode.BadRequest,
    unauthorized_client: HttpStatusCode.BadRequest,
    unsupported_response_type: HttpStatusCode.BadRequest,
    unsupported_response_mode: HttpStatusCode.BadRequest,
    invalid_scope: HttpStatusCode.BadRequest,
    unsupported_grant_type: HttpStatusCode.BadRequest,
    invalid_grant: HttpStatusCode.BadRequest,
    invalid_state: HttpStatusCode.BadRequest,
    insufficient_entropy: HttpStatusCode.BadRequest,
    token_signature_mismatch: HttpStatusCode.BadRequest,
    request_not_supported: HttpStatusCode.BadRequest,
    request_uri_not_supported: HttpStatusCode.BadRequest,
    registration_not_supported: HttpStatusCode.BadRequest,
    jti_known: HttpStatusCode.BadRequest,
    invalid_token: HttpStatusCode.Unauthorized,
    request_unauthorized: HttpStatusCode.Unauthorized,
    invalid_client: HttpStatusCode.Unauthorized,
    token_claim: HttpStatusCode.Unauthorized,
    token_inactive: HttpStatusCode.Unauthorized,
    request_forbidden: HttpStatusCode.Forbidden,
    scope_not_granted: HttpStatusCode.Forbidden,
    access_denied: HttpStatusCode.Forbidden,
    not_found: HttpStatusCode.NotFound,
    temporarily_unavailable: HttpStatusCode.ServiceUnavailable,
    misconfiguration: HttpStatusCode.InternalServerError,
    server_error: HttpStatusCode.InternalServerError,
    error: HttpStatusCode.InternalServerError,
  };

  return errorStatusMapping[error] || HttpStatusCode.InternalServerError;
};

export const OAuth2Error = (error: string) =>
  new AppError({
    ...defaultError,
    statusCode: oAuth2ErrorHttpStatus(error),
  });
